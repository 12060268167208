import { AccreditationFields } from "@/types/event";

export type Fields = {
  accreditationField: AccreditationFields;
  value: JSON;
};

export type Accreditation = {
  fields: AccreditationFields[];
};

export enum AccreditationTypes {
  PENDING = "pending",
  CANCELED = "canceled",
  INCOMPLETE = "incomplete",
  REJECTED = "rejected",
  GRANTED = "granted",
}

export enum ApplicationListStatuses {
  GRANTED = "granted",
  PENDING = "pending",
  OTHER = "other",
}
