import React from "react";
import getConfig from "next/config";
import { Image, ImageProps } from "@chakra-ui/react";

import { Language, LanguageShort } from "@/types/common";
import { getFlag, getFlagShort, languages } from "@/utils/language";

const { publicRuntimeConfig } = getConfig();

type Props = {
  language: Language | LanguageShort;
};

const FlagImage = ({
  language,
  ...props
}: Props & ImageProps): React.ReactElement => {
  return (
    <Image
      src={`${publicRuntimeConfig.basePath || ""}/flags/${
        languages[language] ? getFlag(language) : getFlagShort(language)
      }`}
      boxSize="20px"
      objectFit="cover"
      _hover={props?.onClick && { cursor: "pointer" }}
      {...props}
    />
  );
};

export default FlagImage;
