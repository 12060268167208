import Avatar from "@/components/common/Avatar";
import FlagImage from "@/components/common/FlagImage";
import Link from "@/components/common/Link";
import { useToastPromise } from "@/hooks/useToast";
import { LanguageShort } from "@/types/common";
import { Website } from "@/types/website";
import { prependURL } from "@/utils/helpers";
import paths from "@/utils/paths";
import {
  AvatarProps,
  Box,
  Flex,
  HStack,
  Icon,
  Link as StyledLink,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import React from "react";
import { Book, Edit2, ExternalLink, Star } from "react-feather";
import { mutate } from "swr";

type BadgeProps = {
  type: "press" | "editorial" | "user" | "organization";
  id?: string | number;
  name: string;
  picture: string;
  subscribed?: boolean;
  website?: Website;
  url?: string;
  locale?: LanguageShort;
  showOfficeSubscribeIcon?: boolean;
  showOfficeProfileIcon?: boolean;
  notActivated?: boolean;
  notActivatedInformation?: boolean;
  onUpdate?: () => void;
  avatarSize?: AvatarProps["size"];
  children?: JSX.Element | JSX.Element[];
};

const User = ({
  id,
  name,
  type,
  picture,
  subscribed,
  website,
  url,
  locale,
  onUpdate,
  showOfficeSubscribeIcon = true,
  showOfficeProfileIcon = true,
  notActivated = false,
  notActivatedInformation = false,
  avatarSize = "sm",
  children,
}: BadgeProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const toast = useToastPromise();

  const subscribe = () => {
    if (type !== "press") return;
    return toast.promise(
      axios
        .post(`/press-office/${id}/subscription`, {
          status: subscribed ? "unsubscribe" : "subscribe",
        })
        .then(() => {
          if (onUpdate) onUpdate();
          mutate("/press-office?latest=true");
        })
    );
  };

  return (
    <Flex align="center" whiteSpace={{ base: "nowrap", md: "normal" }}>
      {type === "user" && locale && (
        <FlagImage mr={2} language={locale} d={{ base: "none", md: "block" }} />
      )}
      {(type === "editorial" || type === "user" || type === "organization") && (
        <>
          <Avatar
            src={picture}
            name={name}
            mr={3}
            opacity={notActivated ? "50%" : undefined}
            size={avatarSize}
          />
        </>
      )}
      {type === "press" && (
        <Link to={`/offices/office/${id}`}>
          <Avatar src={picture} name={name} mr={3} size={avatarSize} />
        </Link>
      )}
      <Box>
        {type === "editorial" && (
          <Text lineHeight={1.2} color={notActivated ? "gray.300" : undefined}>
            {name}
            {notActivated && notActivatedInformation && (
              <Tooltip
                hasArrow
                label={t(
                  "editorialOffice.accreditationInformation.pendingStatus"
                )}
                placement="top"
              >
                <StyledLink
                  lineHeight="1"
                  display="inline-flex"
                  alignItems="center"
                  p="1"
                  fontSize=".875rem"
                  ml={1}
                  color="red.500"
                  _hover={{ color: "red.800" }}
                  verticalAlign="middle"
                >
                  <Icon as={Edit2} />
                </StyledLink>
              </Tooltip>
            )}
          </Text>
        )}
        {type === "press" && id && (
          <Link to={`/offices/office/${id}`}>
            <Text lineHeight={1.2}>{name}</Text>
          </Link>
        )}
        {((type === "press" && !id) ||
          type === "user" ||
          type === "organization") && (
          <Flex>
            <Text lineHeight={1.2}>{name}</Text>
            {type === "user" && locale && (
              <FlagImage
                ml={2}
                language={locale}
                d={{ base: "block", md: "none" }}
              />
            )}
          </Flex>
        )}
        {(type === "press" || url) && (
          <HStack alignItems="center" spacing={2}>
            {((website && !website.disabled) || url) && (
              <NextLink
                passHref
                href={
                  !url
                    ? paths.getWebsiteHref(
                        "/",
                        website?.slug,
                        website?.isSubdomain
                      )
                    : prependURL(url)
                }
              >
                <StyledLink isExternal>
                  <Tooltip
                    hasArrow
                    label={t(
                      type === "press"
                        ? "website.pressWebsite"
                        : "editorialOffice.editorialOfficeWebsite"
                    )}
                    placement="top"
                  >
                    <Icon as={ExternalLink} w="20px" h="20px" />
                  </Tooltip>
                </StyledLink>
              </NextLink>
            )}
            {type === "press" && (
              <>
                {showOfficeProfileIcon && (
                  <Link to={`/offices/office/${id}`}>
                    <Tooltip
                      hasArrow
                      label={t("pressOffice.pressOfficeProfile")}
                      placement="top"
                    >
                      <Icon as={Book} w="20px" h="20px" />
                    </Tooltip>
                  </Link>
                )}
                {showOfficeSubscribeIcon && (
                  <Box color="text.400" cursor="pointer" onClick={subscribe}>
                    <Tooltip
                      hasArrow
                      label={
                        subscribed
                          ? t("website.unsubscribe")
                          : t("website.subscribe")
                      }
                      placement="top"
                    >
                      <Icon
                        fill={subscribed ? "currentcolor" : "none"}
                        as={Star}
                        w="20px"
                        h="20px"
                      />
                    </Tooltip>
                  </Box>
                )}
              </>
            )}
          </HStack>
        )}
        {children}
      </Box>
    </Flex>
  );
};

export default User;
