import React from "react";
import { Spinner } from "@chakra-ui/react";
import { Event } from "@/types/event";
import { useUser } from "@/hooks/useUser";

import ParsedHTML from "@/components/common/ParsedHTML";

const EventName = ({ event }: { event: Event }): JSX.Element => {
  const [session, loading] = useUser();
  const lang = session?.user?.profile?.locale;

  if (loading) return <Spinner />;

  return (
    <ParsedHTML
      html={
        event?.description?.[lang] ??
        event?.description?.en ??
        event?.description?.[Object.keys(event?.description)[0]] ??
        ""
      }
    />
  );
};

export default EventName;
