import React, { useState } from "react";

import { Tooltip, Link as StyledLink, Icon, LinkProps } from "@chakra-ui/react";
import { HelpCircle } from "react-feather";
import { useTranslation } from "next-i18next";

type Props = {
  label: string;
  iconW?: number;
  iconH?: number;
};

const HelpTooltip = ({
  label,
  iconW,
  iconH,
  ...rest
}: Props & LinkProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const helpLabel = t(`help.${label}`);
  let helpTip = null;

  const [isLabelOpen, setIsLabelOpen] = useState(false);

  if (helpLabel !== "") {
    helpTip = (
      <Tooltip hasArrow label={helpLabel} placement="top" isOpen={isLabelOpen}>
        <StyledLink
          lineHeight="1"
          display="inline-flex"
          alignItems="center"
          p="1"
          fontSize=".875rem"
          {...rest}
          onMouseEnter={() => setIsLabelOpen(true)}
          onMouseLeave={() => setIsLabelOpen(false)}
          onClick={() => setIsLabelOpen(true)}
        >
          <Icon
            as={HelpCircle}
            w={typeof iconW === "number" ? iconW : undefined}
            h={typeof iconH === "number" ? iconH : undefined}
          />
        </StyledLink>
      </Tooltip>
    );
  }

  return helpTip;
};

export default HelpTooltip;
