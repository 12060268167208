/* eslint-disable react/display-name */
import Link from "@/components/common/Link";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { CheckSquare, Edit, FileText } from "react-feather";

import EventDescription from "@/components/Events/EventDescription";
import Avatar from "@/components/common/Avatar";
import BoxCard from "@/components/common/BoxCard";
import HelpTooltip from "@/components/common/HelpTooltip";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import Table from "@/components/common/Table";
import User from "@/components/common/UserOfficeBadge";
import useIsMobile from "@/hooks/useIsMobile";
import { useUser } from "@/hooks/useUser";
import { AccreditationTypes } from "@/types/accreditation";
import {
  getAccreditationPhoto,
  getAccreditationStatusColor,
  getAccreditationStatusIcon,
} from "@/utils/accreditation";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { useTranslation } from "next-i18next";
import useSWR, { mutate } from "swr";

const tabIndex = {
  events: 0,
  myApplications: 1,
};

type Props = {
  subscribedEventsOnly?: boolean;
  tab?: string;
};

// TODO: Move tables with data fetching to separate components
const Accreditation = ({
  subscribedEventsOnly = false,
  tab,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useUser();
  const router = useRouter();

  const { data: eventData } = useSWR(
    !subscribedEventsOnly ? "/event/user?active=true" : null
  );
  const { data: userAccreditationsData } = useSWR(
    !subscribedEventsOnly ? "/accreditation/user" : null
  );
  const { data: subscribedEventsData = [], error } = useSWR(
    subscribedEventsOnly ? "/event/subscribed" : null
  );
  const isSubscribedEventsLoading = !subscribedEventsData && !error;
  const isMobile = useIsMobile();
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex[tab] || 0);

  const update = () => {
    mutate("/press-office/subscribed");
    mutate("/user/press-office");
    mutate("/press-office");
  };

  const tabsOptions = useMemo(
    () => [
      {
        value: 0,
        label: t("events.events"),
      },
      {
        value: 1,
        label: t("events.myApplications"),
      },
    ],
    []
  );

  const tabsTooltips = {
    0: "accreditation.events",
    1: "accreditation.myApplications",
  };

  const eventColumns = useMemo(
    () => [
      {
        Header: t("pressOffice.pressOffice"),
        accessor: "pressOffice.name",
        Cell: ({ value, row }) => (
          <User
            type="press"
            name={value}
            id={row?.original?.pressOffice?.id}
            picture={row?.original?.pressOffice?.picture}
            website={row?.original?.pressOffice?.website}
            url={row?.original?.pressOffice?.url}
            subscribed={!!row?.original?.pressOffice?.subscribers?.length}
            onUpdate={update}
            showOfficeProfileIcon={false}
          />
        ),
      },
      {
        Header: t("events.event"),
        accessor: "name",
        Cell: ({ row }) => (
          <Flex align="center">
            <Popover trigger="hover" isLazy>
              <PopoverTrigger>
                <Avatar
                  src={
                    row?.original?.picture
                      ? `/uploads/${row?.original?.picture}`
                      : null
                  }
                  size="sm"
                  mr={4}
                  name={row?.original?.name}
                />
              </PopoverTrigger>
              {row?.original?.description && (
                <Portal>
                  <PopoverContent maxHeight="300px" overflow="auto">
                    <PopoverArrow />
                    <PopoverHeader>
                      <Flex flex="1" alignItems="center">
                        <Avatar
                          src={
                            row?.original?.picture
                              ? `/uploads/${row?.original?.picture}`
                              : null
                          }
                          size="sm"
                          name={row?.original?.name}
                        />
                        <Text ml={3} fontWeight="500">
                          {row?.original?.name}
                        </Text>
                      </Flex>
                    </PopoverHeader>
                    <PopoverBody>
                      <Text textAlign={"justify"}>
                        <EventDescription event={row?.original} />
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              )}
            </Popover>
            <Text fontWeight="500">{row?.original?.name}</Text>
          </Flex>
        ),
      },
      {
        Header: ({}) =>
          subscribedEventsOnly ? (
            t("settings.start")
          ) : (
            <Flex align="center">
              {t("settings.start")}
              <HelpTooltip label="accreditation.start" />
            </Flex>
          ),
        accessor: "startDate",
        Cell: ({ value }) => (
          <Text>{value ? format(new Date(value), "dd/MM/yyyy") : "-"}</Text>
        ),
      },
      {
        Header: ({}) =>
          subscribedEventsOnly ? (
            t("settings.end")
          ) : (
            <Flex align="center">
              {t("settings.end")}
              <HelpTooltip label="accreditation.end" />
            </Flex>
          ),
        accessor: "endDate",
        Cell: ({ value }) => (
          <Text>{value ? format(new Date(value), "dd/MM/yyyy") : "-"}</Text>
        ),
      },
      {
        disableSortBy: true,
        id: "event",
        accessor: "id",
        Cell: ({ value, row }) => {
          const now = new Date();
          const startAccreditation =
            row?.original?.accreditationFrom &&
            new Date(row?.original?.accreditationFrom);
          const endAccreditation =
            row?.original?.accreditationTo &&
            new Date(row?.original?.accreditationTo);
          const submittedAcreditation = row?.original?.accreditations?.find(
            (accreditation) => accreditation?.userId === session?.user?.id
          );

          return (
            <Flex
              width="100%"
              justifyContent="flex-end"
              fontSize="15px"
              direction="column"
            >
              {submittedAcreditation ? (
                <Button
                  bgColor={getAccreditationStatusColor(
                    submittedAcreditation?.status
                  )}
                  size="sm"
                  _hover={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  _focus={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  onClick={() => {
                    if (
                      submittedAcreditation?.status ===
                        AccreditationTypes.PENDING ||
                      submittedAcreditation?.status ===
                        AccreditationTypes.INCOMPLETE
                    )
                      router.push(`/accreditation/event/${value}`);
                    else return null;
                  }}
                >
                  <Icon
                    as={getAccreditationStatusIcon(
                      submittedAcreditation?.status
                    )}
                    w={5}
                    h={5}
                    mr={2}
                  />
                  {t(`events.statusInfo.${submittedAcreditation?.status}`)}
                </Button>
              ) : (
                <>
                  {!row?.original?.accreditationActive &&
                    startAccreditation &&
                    new Date() < endAccreditation && (
                      <Text
                        color="text.500"
                        fontSize="xs"
                        mt={1}
                        textAlign="left"
                      >
                        {t("events.accreditationFrom", {
                          date: format(startAccreditation, "dd/MM/yyyy HH:mm"),
                        })}
                      </Text>
                    )}
                  {!row?.original?.accreditationActive &&
                    new Date() > endAccreditation && (
                      <Text
                        color="text.500"
                        fontSize="xs"
                        mt={1}
                        textAlign="left"
                      >
                        {t("events.accreditationEnded")}
                      </Text>
                    )}
                  {!row?.original?.accreditationActive && !startAccreditation && (
                    <Text
                      color="text.500"
                      fontSize="xs"
                      mt={1}
                      textAlign="left"
                    >
                      {t("events.accreditationNotStarted")}
                    </Text>
                  )}
                  {row?.original?.accreditationActive && endAccreditation && (
                    <>
                      <Button
                        size="sm"
                        onClick={() =>
                          router.push(`/accreditation/event/${value}`)
                        }
                      >
                        <Icon as={CheckSquare} w={5} h={5} mr={2} />
                        {t("events.accredit")}
                      </Button>
                      <Text
                        color="text.500"
                        fontSize="xs"
                        mt={1}
                        textAlign="left"
                      >
                        {t("events.accreditationTo", {
                          date: format(endAccreditation, "dd/MM/yyyy HH:mm"),
                        })}
                      </Text>
                    </>
                  )}
                </>
              )}
            </Flex>
          );
        },
      },
    ],
    []
  );

  const accreditationColumns = useMemo(
    () => [
      {
        Header: t("events.accreditationFiledAs"),
        accessor: ({ editorialOffice }) =>
          editorialOffice ? editorialOffice?.name : t("events.freelancer"),
        Cell: ({ row }) => {
          let avatarSrc = null;

          const accreditationPhoto = getAccreditationPhoto(
            row?.original?.fields
          );

          if (!row?.original?.editorialOffice)
            avatarSrc =
              row?.original?.user?.profile?.picture || accreditationPhoto
                ? `/uploads/${
                    accreditationPhoto ?? row?.original?.user?.profile?.picture
                  }`
                : null;

          if (
            row?.original?.editorialOffice &&
            row?.original?.editorialOffice?.picture
          )
            avatarSrc = `/uploads/${row?.original?.editorialOffice?.picture}`;

          return (
            <Flex align="center">
              <Avatar
                src={avatarSrc}
                size="sm"
                mr={4}
                name={
                  row?.original?.editorialOffice?.name
                    ? row?.original?.editorialOffice?.name
                    : `${session?.user?.firstName} ${session?.user?.lastName}`
                }
              />
              <Text fontWeight="500">
                {row?.original?.editorialOffice?.name
                  ? row?.original?.editorialOffice?.name
                  : t("events.freelancer")}
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: t("events.event"),
        accessor: "event.name",
        Cell: ({ row }) => (
          <Flex align="center">
            <Popover trigger="hover" isLazy>
              <PopoverTrigger>
                <Avatar
                  src={
                    row?.original?.event?.picture
                      ? `/uploads/${row?.original?.event?.picture}`
                      : null
                  }
                  size="sm"
                  mr={4}
                  name={row?.original?.event?.name}
                />
              </PopoverTrigger>
              {row?.original?.event?.description && (
                <Portal>
                  <PopoverContent maxHeight="300px" overflow="auto">
                    <PopoverArrow />
                    <PopoverHeader>
                      <Flex flex="1" alignItems="center">
                        <Avatar
                          src={
                            row?.original?.event?.picture
                              ? `/uploads/${row?.original?.event?.picture}`
                              : null
                          }
                          size="sm"
                          name={row?.original?.event?.name}
                        />
                        <Text ml={3} fontWeight="500">
                          {row?.original?.event?.name}
                        </Text>
                      </Flex>
                    </PopoverHeader>
                    <PopoverBody>
                      <Text textAlign={"justify"}>
                        <EventDescription event={row?.original?.event} />
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              )}
            </Popover>
            <Text fontWeight="500">{row?.original?.event?.name}</Text>
          </Flex>
        ),
      },
      {
        Header: t("settings.start"),
        accessor: ({ event }) => event?.startDate,
        Cell: ({ row }) => (
          <Text>
            {row.original?.event?.startDate
              ? format(new Date(row?.original?.event?.startDate), "dd/MM/yyyy")
              : "-"}
          </Text>
        ),
      },
      {
        Header: t("settings.end"),
        accessor: ({ event }) => event?.endDate,
        Cell: ({ row }) => (
          <Text>
            {row?.original?.event?.endDate
              ? format(new Date(row?.original?.event?.endDate), "dd/MM/yyyy")
              : "-"}
          </Text>
        ),
      },
      {
        Header: t("events.status"),
        accessor: "status",
        Cell: ({ value, row }) => (
          <Tooltip
            hasArrow
            label={
              row?.original?.status === AccreditationTypes.PENDING ||
              row?.original?.status === AccreditationTypes.INCOMPLETE
                ? t("edit")
                : null
            }
            placement="top"
          >
            <Badge
              variant={"solid"}
              bgColor={getAccreditationStatusColor(value)}
              width={100}
              onClick={() => {
                if (
                  row?.original?.status === AccreditationTypes.PENDING ||
                  row?.original?.status === AccreditationTypes.INCOMPLETE
                )
                  router.push(
                    `/accreditation/event/${row?.original?.event?.id}`
                  );
                else return null;
              }}
            >
              <Text fontWeight="500" textAlign={"center"}>
                {t(`events.${value}`)}
              </Text>
            </Badge>
          </Tooltip>
        ),
      },
      {
        id: "action",
        accessor: ({ event }) => event?.id,
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <>
            {row?.original?.status === AccreditationTypes.PENDING ||
            row?.original?.status === AccreditationTypes.INCOMPLETE ? (
              <Flex width="100%" justifyContent="flex-end" fontSize="20px">
                <Tooltip hasArrow label={t("edit")} placement="top">
                  <Link to={`/accreditation/event/${value}`}>
                    <Icon as={Edit} />
                  </Link>
                </Tooltip>
              </Flex>
            ) : null}
          </>
        ),
      },
    ],
    []
  );

  const subscribedEventsOnlyMobile = useMemo(
    () =>
      subscribedEventsData?.map((row) => {
        let avatarSrc = null;

        const accreditationPhoto = getAccreditationPhoto(row?.fields);

        const submittedAcreditation = row?.accreditations?.find(
          (accreditation) => accreditation?.userId === session?.user?.id
        );

        if (!row?.editorialOffice)
          avatarSrc =
            row?.user?.profile?.picture || accreditationPhoto
              ? `/uploads/${accreditationPhoto ?? row?.user?.profile?.picture}`
              : null;

        if (row?.editorialOffice && row?.editorialOffice?.picture)
          avatarSrc = `/uploads/${row?.editorialOffice?.picture}`;

        return (
          <BoxCard p={0} key={row?.id}>
            <Flex
              align="center"
              justify="space-between"
              p="1.25rem"
              borderBottomColor="light.50"
              borderBottomWidth="1px"
            >
              <User
                type="press"
                name={row.pressOffice.name}
                id={row?.pressOffice?.id}
                picture={row?.pressOffice?.picture}
                website={row?.pressOffice?.website}
                url={row?.pressOffice?.url}
                subscribed={!!row?.pressOffice?.subscribers?.length}
                onUpdate={update}
                showOfficeProfileIcon={false}
                avatarSize="md"
              />
            </Flex>
            <Box p="1.25rem">
              <Flex align="center">
                <Avatar
                  name={row.name}
                  size="sm"
                  mr={3}
                  src={`/uploads/${row?.picture}`}
                />
                <Box>
                  <Text fontWeight="500">{row.name}</Text>
                  <Text color="text.500" fontSize="xs">
                    {row?.startDate
                      ? format(new Date(row?.startDate), "dd/MM/yyyy")
                      : ""}
                    &nbsp;-&nbsp;
                    {row?.endDate
                      ? format(new Date(row?.endDate), "dd/MM/yyyy")
                      : "-"}
                  </Text>
                </Box>
              </Flex>
              {submittedAcreditation ? (
                <Button
                  bgColor={getAccreditationStatusColor(
                    submittedAcreditation?.status
                  )}
                  size="sm"
                  _hover={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  _focus={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  mt={3}
                  onClick={() => {
                    if (
                      submittedAcreditation?.status ===
                        AccreditationTypes.PENDING ||
                      submittedAcreditation?.status ===
                        AccreditationTypes.INCOMPLETE
                    )
                      router.push(`/accreditation/event/${value}`);
                    else return null;
                  }}
                >
                  <Icon
                    as={getAccreditationStatusIcon(
                      submittedAcreditation?.status
                    )}
                    w={5}
                    h={5}
                    mr={2}
                  />
                  {t(`events.statusInfo.${submittedAcreditation?.status}`)}
                </Button>
              ) : (
                <Link to={`/accreditation/event/${row.id}`}>
                  <Button size="sm" mt={3}>
                    <Icon as={CheckSquare} w={5} h={5} mr={2} />
                    {t("events.accredit")}
                  </Button>
                </Link>
              )}
            </Box>
          </BoxCard>
        );
      }),
    [subscribedEventsData]
  );

  return (
    <Box w="100%" maxW={{ base: "calc(100vw - 56px)", md: "100%" }} zIndex={9}>
      <Heading as="h2" size="lg">
        {subscribedEventsOnly ? (
          t("events.subscribedEvents")
        ) : (
          <Flex align="center">
            {t("events.accreditation")}
            <HelpTooltip label="accreditation.heading" />
          </Flex>
        )}
      </Heading>
      <BoxCard
        p={0}
        w="100%"
        bg={{ base: "transparent", md: "white" }}
        boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
      >
        {subscribedEventsOnly ? (
          <>
            {isSubscribedEventsLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {!isSubscribedEventsLoading && subscribedEventsData?.length > 0 && (
              <>
                <Box d={{ base: "none", md: "block" }}>
                  <Table
                    columns={eventColumns}
                    data={subscribedEventsData || []}
                    searchBar={false}
                    pagination={false}
                  />
                </Box>
                <SimpleGrid
                  columns={1}
                  spacing={3}
                  d={{ base: "grid", md: "none" }}
                >
                  {subscribedEventsOnlyMobile}
                </SimpleGrid>
              </>
            )}
            {!isSubscribedEventsLoading && subscribedEventsData?.length === 0 && (
              <Box p={3} textAlign="center">
                <Text fontWeight="500">{t("events.noEvents")}</Text>
                <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
                  {t("events.noEventsSubtitle")}
                </Text>
                <Link to="/offices">
                  <Button
                    leftIcon={<FileText />}
                    variant="solid"
                    size="md"
                    mt={5}
                  >
                    {t("pressOffice.pressOffices")}
                  </Button>
                </Link>
              </Box>
            )}
          </>
        ) : (
          <>
            <Tabs
              variant={useBreakpointValue({ base: "buttons", md: "solid" })}
              isLazy
              defaultIndex={tabIndex[tab] || 0}
              onChange={(index) => setSelectedTabIndex(index)}
              index={selectedTabIndex}
            >
              {isMobile && (
                <Flex align={"center"} mb={5}>
                  <Box width={"100%"}>
                    <SelectAdvanced
                      options={tabsOptions}
                      onChange={(selectedValue) =>
                        setSelectedTabIndex(selectedValue?.value)
                      }
                      value={tabsOptions?.find(
                        (c) => selectedTabIndex === c?.value
                      )}
                      lg
                      isClearable={false}
                    />
                  </Box>
                  <HelpTooltip label={tabsTooltips[selectedTabIndex]} />
                </Flex>
              )}
              {!isMobile && (
                <TabList>
                  <Tab>
                    {t("events.events")}
                    <HelpTooltip label="accreditation.events" />
                  </Tab>
                  <Tab>
                    {t("events.myApplications")}
                    <HelpTooltip label="accreditation.myApplications" />
                  </Tab>
                </TabList>
              )}
              <TabPanels>
                <TabPanel>
                  <Box d={{ base: "none", md: "block" }}>
                    <Table columns={eventColumns} data={eventData || []} />
                  </Box>
                  <SimpleGrid
                    columns={1}
                    spacing={3}
                    d={{ base: "grid", md: "none" }}
                  >
                    {eventData?.map((row) => {
                      let avatarSrc = null;

                      const accreditationPhoto = getAccreditationPhoto(
                        row?.fields
                      );

                      if (!row?.editorialOffice)
                        avatarSrc =
                          row?.user?.profile?.picture || accreditationPhoto
                            ? `/uploads/${
                                accreditationPhoto ??
                                row?.user?.profile?.picture
                              }`
                            : null;

                      if (row?.editorialOffice && row?.editorialOffice?.picture)
                        avatarSrc = `/uploads/${row?.editorialOffice?.picture}`;

                      return (
                        <BoxCard p={0} key={row?.id}>
                          <Flex
                            align="center"
                            justify="space-between"
                            p="1.25rem"
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            <User
                              type="press"
                              name={row.pressOffice.name}
                              id={row?.pressOffice?.id}
                              picture={row?.pressOffice?.picture}
                              website={row?.pressOffice?.website}
                              url={row?.pressOffice?.url}
                              subscribed={
                                !!row?.pressOffice?.subscribers?.length
                              }
                              onUpdate={update}
                              showOfficeProfileIcon={false}
                              avatarSize="md"
                            />
                          </Flex>
                          <Box p="1.25rem">
                            <Flex align="center">
                              <Avatar
                                name={row.name}
                                size="sm"
                                mr={3}
                                src={`/uploads/${row?.picture}`}
                              />
                              <Box>
                                <Text fontWeight="500">{row.name}</Text>
                                <Text color="text.500" fontSize="xs">
                                  {row?.startDate
                                    ? format(
                                        new Date(row?.startDate),
                                        "dd/MM/yyyy"
                                      )
                                    : ""}
                                  &nbsp;-&nbsp;
                                  {row?.endDate
                                    ? format(
                                        new Date(row.endDate),
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </Text>
                              </Box>
                            </Flex>
                            <Link to={`/accreditation/event/${row.id}`}>
                              <Button
                                size="sm"
                                leftIcon={<CheckSquare />}
                                mt={3}
                              >
                                {t("events.accredit")}
                              </Button>
                            </Link>
                          </Box>
                        </BoxCard>
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <Box d={{ base: "none", md: "block" }}>
                    <Table
                      columns={accreditationColumns}
                      data={userAccreditationsData || []}
                    />
                  </Box>
                  <SimpleGrid
                    columns={1}
                    spacing={3}
                    d={{ base: "grid", md: "none" }}
                  >
                    {userAccreditationsData?.map((row) => {
                      let avatarSrc = null;

                      const accreditationPhoto = getAccreditationPhoto(
                        row?.original?.fields
                      );

                      if (!row?.original?.editorialOffice)
                        avatarSrc =
                          row?.original?.user?.profile?.picture ||
                          accreditationPhoto
                            ? `/uploads/${
                                accreditationPhoto ??
                                row?.original?.user?.profile?.picture
                              }`
                            : null;

                      if (
                        row?.original?.editorialOffice &&
                        row?.original?.editorialOffice?.picture
                      )
                        avatarSrc = `/uploads/${row?.original?.editorialOffice?.picture}`;

                      return (
                        <BoxCard p={0} key={row?.id}>
                          <Flex
                            align="center"
                            justify="space-between"
                            p="1.25rem"
                            borderBottomColor="light.50"
                            borderBottomWidth="1px"
                          >
                            <Flex align="center">
                              <Popover trigger="hover" isLazy>
                                <PopoverTrigger>
                                  <Avatar
                                    src={
                                      row.event?.picture
                                        ? `/uploads/${row.event?.picture}`
                                        : null
                                    }
                                    size="md"
                                    mr={4}
                                    name={row.event?.name}
                                  />
                                </PopoverTrigger>
                                {row?.event?.description && (
                                  <Portal>
                                    <PopoverContent
                                      maxHeight="300px"
                                      overflow="auto"
                                    >
                                      <PopoverArrow />
                                      <PopoverHeader>
                                        <Flex flex="1" alignItems="center">
                                          <Avatar
                                            src={
                                              row.event?.picture
                                                ? `/uploads/${row.event?.picture}`
                                                : null
                                            }
                                            size="sm"
                                            name={row.event?.name}
                                          />
                                          <Text ml={3} fontWeight="500">
                                            {row.event?.name}
                                          </Text>
                                        </Flex>
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <Text textAlign={"justify"}>
                                          <EventDescription event={row.event} />
                                        </Text>
                                      </PopoverBody>
                                    </PopoverContent>
                                  </Portal>
                                )}
                              </Popover>
                              <Box>
                                <Text fontWeight="500">{row.event?.name}</Text>
                                <Text color="text.500" fontSize="xs">
                                  {row?.event?.startDate
                                    ? format(
                                        new Date(row?.event?.startDate),
                                        "dd/MM/yyyy"
                                      )
                                    : ""}
                                  &nbsp;-&nbsp;
                                  {row?.event?.endDate
                                    ? format(
                                        new Date(row?.event?.endDate),
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </Text>
                                <Badge
                                  mt={1}
                                  variant={"solid"}
                                  bgColor={getAccreditationStatusColor(
                                    row.status
                                  )}
                                  fontSize="10px"
                                  px="5.5px"
                                  py="0"
                                  onClick={() => {
                                    if (
                                      row?.status ===
                                        AccreditationTypes.PENDING ||
                                      row?.status ===
                                        AccreditationTypes.INCOMPLETE
                                    )
                                      router.push(
                                        `/accreditation/event/${row?.event?.id}`
                                      );
                                    else return null;
                                  }}
                                >
                                  <Text fontWeight="500" textAlign={"center"}>
                                    {t(`events.${row.status}`)}
                                  </Text>
                                </Badge>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex p="1.25rem">
                            <Flex align="center">
                              <Avatar
                                src={avatarSrc}
                                size="sm"
                                mr={4}
                                name={
                                  row?.editorialOffice?.name
                                    ? row?.editorialOffice?.name
                                    : `${session?.user?.firstName} ${session?.user?.lastName}`
                                }
                              />
                              <Text fontWeight="500">
                                {row?.editorialOffice?.name
                                  ? row?.editorialOffice?.name
                                  : t("events.freelancer")}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            p="1.25rem"
                            justifyContent="flex-end"
                            fontSize="20px"
                            borderTopColor="light.50"
                            borderTopWidth="1px"
                          >
                            {row?.status === AccreditationTypes.PENDING ||
                            row?.status === AccreditationTypes.INCOMPLETE ? (
                              <Flex
                                width="100%"
                                justifyContent="flex-end"
                                fontSize="20px"
                              >
                                <Tooltip
                                  hasArrow
                                  label={t("edit")}
                                  placement="top"
                                >
                                  <Link
                                    to={`/accreditation/event/${row?.event?.id}`}
                                  >
                                    <Icon as={Edit} />
                                  </Link>
                                </Tooltip>
                              </Flex>
                            ) : null}
                          </Flex>
                        </BoxCard>
                      );
                    })}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </BoxCard>
    </Box>
  );
};

export default Accreditation;
