import { AccreditationTypes } from "@/types/accreditation";
import {
  CheckCircle,
  XCircle,
  PauseCircle,
  PieChart,
  AlertCircle,
} from "react-feather";

export const accreditationTypes = [
  AccreditationTypes.PENDING,
  AccreditationTypes.GRANTED,
  AccreditationTypes.INCOMPLETE,
  AccreditationTypes.REJECTED,
  AccreditationTypes.CANCELED,
];

export const accreditationColors: { [key in AccreditationTypes]: string } = {
  [AccreditationTypes.PENDING]: "#fdcb6e",
  [AccreditationTypes.CANCELED]: "#e17055",
  [AccreditationTypes.GRANTED]: "#28a745",
  [AccreditationTypes.INCOMPLETE]: "#636e72",
  [AccreditationTypes.REJECTED]: "#dc3545",
};

export const accreditationIcons: { [key in AccreditationTypes] } = {
  [AccreditationTypes.PENDING]: PauseCircle,
  [AccreditationTypes.CANCELED]: AlertCircle,
  [AccreditationTypes.GRANTED]: CheckCircle,
  [AccreditationTypes.INCOMPLETE]: PieChart,
  [AccreditationTypes.REJECTED]: XCircle,
};

export const getAccreditationStatusColor = (status: string): string =>
  accreditationColors[status] ?? undefined;

export const getAccreditationStatusIcon = (status: string) =>
  accreditationIcons[status] ?? undefined;

export const getAccreditationPhoto = (fields): string => {
  const accreditationPhoto = fields?.filter(
    (field) =>
      field?.accreditationField?.default &&
      field?.accreditationField?.name?.en === "photo" &&
      field?.value?.filename
  );

  return accreditationPhoto?.length > 0
    ? accreditationPhoto[0]?.value?.filename
    : null;
};
